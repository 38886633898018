import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "../components/Strip";
import { EventCard } from "./EventCard";
import { useLinksQuery } from "../queries/queries";
// import { EventBriteCard } from "./EventBriteCard";
import { StripSkeleton } from "./StripSkeleton";

//Need to add date sorting.
// .filter((item) => new Date(item.date) >= new Date())
// .sort((a, b) => new Date(a.date) - new Date(b.date))

export function EventsStrip() {
  const STRIP = "Events";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="events"
        nav="scroll"
        gradientValues={["indigo"]}
        title="Events"
        icon={{ type: "emoji", icon: "📅" }}
        iconColor="white"
      >
        {linksDataByStrip?.map((item, index) => {
          return (
            <EventCard
              key={index}
              event={item.event}
              date={item.date}
              address={item.address}
            />
          );
        })}
      </Strip>
    );
  }
}
