import { useState, useEffect } from "react";
import { ScrollArea } from "@mantine/core";
import ActivityCalendar from "react-activity-calendar";
import axios from "axios";
import { baseUrl } from "../api/links";

//Need to have a non-sensitive identifier – like the userId to signal the refresh token from the backend.

export function StravaCalendar({ username }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [clubs, setClubs] = useState([]);

  async function getStravaData(username) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/get-strava-data/${username}`
      );
      setData(response.data.coloredHeatmapData);
      // setClubs(response.data.clubsArray);
    } catch (error) {
      console.error("Error while fetching Strava data:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // Check if username exists before making the API call
    if (username) {
      getStravaData(username);
    }
  }, [username]);

  // Render nothing if username is not provided
  if (!username) {
    return null;
  }

  // Sum the count values
  const totalMiles = data.reduce((acc, item) => acc + item.count, 0);

  // Round the total
  const roundedTotalMiles = Math.round(totalMiles);

  return (
    <ScrollArea>
      <div style={{ minWidth: "700px" }}>
        <ActivityCalendar
          loading={loading}
          data={data}
          color="red"
          theme={{
            level0: "#EBEDF0",
            level1: "#ffb895",
            level2: "#ff854a",
            level3: "#FC4C02",
            level4: "#CC4200",
          }}
          labels={{
            months: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            totalCount: `${roundedTotalMiles} miles in the last 12 months`,
            tooltip: "{{count}} miles on {{date}}",
          }}
          style={{
            color: "#444444",
          }}
          showWeekdayLabels
        ></ActivityCalendar>
      </div>
    </ScrollArea>
  );
}
