import {
  Avatar,
  Button,
  Flex,
  Rating,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { FEATURED_USERS_ARRAY } from "./FeaturedProfiles";

export function SectionHero() {
  return (
    <Stack
      p="xs"
      pt={16}
      pb={6}
      align="center"
      sx={{
        backgroundColor: "#F6F9FC",
      }}
    >
      <Title order={1} color="#444" size={44} weight={900} p="sm" ta="center">
        A <span class="gradient-text">link-in-bio</span> tool that <br></br>
        engages & converts
      </Title>
      <Text size="lg" color="#444" ta="center" id="how-it-works">
        <b>Reduce friction</b> and <b>add social proof</b> by <i>embedding</i>{" "}
        podcasts, videos, newsletters, and social presence on one page.
      </Text>
      <Stack spacing={6} align="center" pt={6}>
        <Button
          variant="gradient"
          gradient={{ from: "#516bfa", to: "#009bfa", deg: 35 }}
          component="a"
          href="https://tapistr.ee/sign-up"
          size="md"
          radius="md"
          miw={300}
        >
          Try for free
        </Button>
        <Text size="xs">No credit card required</Text>
      </Stack>
      <Flex align="center">
        <Flex m="xs">
          {FEATURED_USERS_ARRAY.slice(0, 5).map((user) => (
            <div style={{ marginRight: "-10px" }} key={user.username}>
              <Avatar radius="xl" src={user.profPic}></Avatar>
            </div>
          ))}
        </Flex>
        <Stack spacing={4} m="xs">
          <Rating
            size="sm"
            color="orange"
            value={5.0}
            readOnly
            fractions={10}
          />
          <Text size="xs" maw={175} color="dimmed">
            Helping creators engage their more than 5,000,000 followers
          </Text>
        </Stack>
      </Flex>
    </Stack>
  );
}
