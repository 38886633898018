import axios from "axios";

export const baseUrl = process.env.REACT_APP_BACKEND_URL;

export const linksApi = axios.create({
  baseURL: baseUrl,
});

export async function getLinks(userId) {
  try {
    const response = await linksApi.get(`/get-urls/${userId}`);
    return response.data.reverse();
  } catch (error) {
    console.error("Error while fetching links:", error);
    // You can return an empty array or a custom error object based on your requirements
    return [];
  }
}

export async function getProfile(username) {
  try {
    const response = await linksApi.get(`/get-profile/${username}`);
    return response.data;
  } catch (error) {
    console.error(`Error while fetching profile for user ${username}:`, error);
    // You can return an empty object or a custom error object based on your requirements
    // return {
    //   success: false,
    //   error: {
    //     message: `Error while fetching profile for user ${username}`,
    //     detail: error.message || "An unknown error occurred",
    //   },
    // };
    throw error.message;
  }
}

export async function getAllProfiles({ token }) {
  try {
    const response = await linksApi.get(`/get-super-user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error(`Error while fetching profile for users:`, error);
    throw error.message;
  }
}

export async function getLinktree(username) {
  try {
    const response = await linksApi.get(`/linktree-convert/${username}`);
    return response.data;
  } catch (error) {
    console.error("Error while importing Linktree:", error);
    return [];
  }
}

export async function getLoggedIn(clerkUserId) {
  try {
    const response = await linksApi.get(`/get-username/${clerkUserId}`);
    return response.data;
  } catch (error) {
    console.error(
      `Error while fetching profile for user ${clerkUserId}:`,
      error
    );
    // You can return an empty object or a custom error object based on your requirements
    throw error;
  }
}

export async function updateProfile({ username, updates, token }) {
  try {
    const response = await linksApi.put(
      `${baseUrl}/update-profile/${username}`,
      updates,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function addBooks({ userId, isbn, token }) {
  try {
    const response = await linksApi.post(
      `${baseUrl}/add-books/${userId}`,
      { isbn: isbn },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function exchangeStravaToken(code, token) {
  try {
    const response = await linksApi.post(
      `${baseUrl}/exchange-strava-token`,
      { code },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getAnalytics({ userId, token }) {
  try {
    const response = await linksApi.get(`${baseUrl}/get-analytics/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//Will attach a header with an Authorization: Bearer token
//Pass the access token from the current user's session
//Backend will read the header, ensure there is a header, make sure there's a bearer token, otherwise return a 401 (unauthorized)
//If there is a token, take it and validate it with Cognito and get the user. Expose context to the route (user's info from Cognito).
//Allow a user to create a link and tie it to their userId.
//If Editing or Deleting, one extra step: make sure they're editing or deleting a link that THEY own. AND userId = loggedInUser

//createBook, createTweet => calls createLink with the relevant fields and make them required for the item.
export async function createLink({
  id,
  url,
  isProfileUrl,
  userId,
  username,
  strip,
  title,
  isbn,
  authorName,
  coverImageUrl,
  favicon,
  imageFit,
  ratingsAverage,
  ratingsCount,
  userRating,
  urlPosition,
  experienceType,
  school,
  degree,
  fieldOfStudy,
  gradePointAverage,
  company,
  jobTitle,
  location,
  description,
  jobDescription,
  startMonth,
  startYear,
  endMonth,
  endYear,
  currentPosition,
  programmingLanguages,
  token,
  substackStripColor,
  substackTitleColor,
  buttonText,
  buttonColor,
}) {
  const response = await linksApi
    .post(
      `/add-url/${userId}`,
      {
        id: id,
        url: url,
        isProfileUrl: isProfileUrl,
        userId: userId, //i.e. Which user is adding this ßto their page? THIS WILL BE REMOVED ONCE SETUP ON THE BACKEND.
        username: username,
        strip: strip,
        title: title,
        isbn: isbn, //Add as uppercase ISBN
        authorName: authorName,
        coverImageUrl: coverImageUrl,
        imageFit: imageFit,
        favicon: favicon,
        ratingsAverage: ratingsAverage,
        ratingsCount: ratingsCount,
        userRating: userRating,
        urlPosition: urlPosition,
        experienceType: experienceType,
        school: school,
        degree: degree,
        fieldOfStudy: fieldOfStudy,
        gradePointAverage: gradePointAverage,
        company: company,
        jobTitle: jobTitle,
        location: location,
        description: description,
        jobDescription: jobDescription,
        startMonth: startMonth,
        startYear: startYear,
        endMonth: endMonth,
        endYear: endYear,
        currentPosition: currentPosition,
        programmingLanguages: programmingLanguages,
        substackStripColor: substackStripColor,
        substackTitleColor: substackTitleColor,
        buttonText: buttonText,
        buttonColor: buttonColor,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response.data;
}

export async function updateLink({
  id,
  url,
  isProfileUrl,
  userId,
  strip,
  title,
  authorName,
  coverImageUrl,
  ratingsAverage,
  ratingsCount,
  userRating,
  urlPosition,
  experienceType,
  school,
  degree,
  fieldOfStudy,
  gradePointAverage,
  company,
  jobTitle,
  location,
  description,
  jobDescription,
  startMonth,
  startYear,
  endMonth,
  endYear,
  currentPosition,
  programmingLanguages,
}) {
  const response = await linksApi
    .put(`/${id}`, {
      id: id,
      url: url,
      isProfileUrl: isProfileUrl,
      userId: userId,
      strip: strip,
      title: title,
      authorName: authorName,
      coverImageUrl: coverImageUrl,
      ratingsAverage: ratingsAverage,
      ratingsCount: ratingsCount,
      userRating: userRating,
      urlPosition: urlPosition,
      experienceType: experienceType,
      school: school,
      degree: degree,
      fieldOfStudy: fieldOfStudy,
      gradePointAverage: gradePointAverage,
      company: company,
      jobTitle: jobTitle,
      location: location,
      description: description,
      jobDescription: jobDescription,
      startMonth: startMonth,
      startYear: startYear,
      endMonth: endMonth,
      endYear: endYear,
      currentPosition: currentPosition,
      programmingLanguages: programmingLanguages,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response.data;
}

export async function deleteLink(id, token) {
  try {
    const response = await linksApi.delete(`/delete-url/${id.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getStrips(username) {
  try {
    const response = await linksApi.get(`/get-strips/${username}`);
    return response.data;
  } catch (error) {
    console.error("Error while fetching strips:", error);
    // You can return an empty array or a custom error object based on your requirements
    return [];
  }
}

export async function addStrip({ id, userId, stripsData: strips, token }) {
  const response = await linksApi
    .post(
      `/add-strip`,
      {
        id: id,
        userId: userId,
        strips: strips,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response.data;
}

export async function getExperience() {
  const response = await linksApi.get(`/get-experience`);
  return response.data;
}

export async function updateStripPositions(updatedStripPositions, token) {
  const response = await linksApi
    .post(`/update-strip-positions`, updatedStripPositions, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response.data;
}

export async function updateUrlPositions(updatedUrlPositions, token) {
  const response = await linksApi
    .post(`/update-url-positions`, updatedUrlPositions, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response.data;
}

export async function updateStripVisibility(updatedStripVisibility, token) {
  try {
    const response = await linksApi.post(
      `/update-strip-visibility`,
      updatedStripVisibility,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateStripColor(updatedStripColor, token) {
  try {
    const response = await linksApi.post(
      `/update-strip-visibility`,
      updatedStripColor,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateFollowButtonVisibility(
  updatedFollowButtonVisibility,
  token
) {
  const response = await linksApi
    .post(`/show-follow-button/`, updatedFollowButtonVisibility, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response.data;
}

export async function deleteStrip(id, token) {
  try {
    const response = await linksApi.delete(`/delete-strip/${id.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createCheckout() {
  return await linksApi
    .post(`/create-checkout`)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

export async function getBookRecommendations(userId) {
  return await linksApi
    .get(`/book-recommendations/${userId}`)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}
