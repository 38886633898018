import "../App.css";
import React, { useState } from "react";
import { Affix, Avatar, Button, LoadingOverlay } from "@mantine/core";
import { LeaveModal } from "../components/LeaveModal";
import { StripRenderer } from "../components/StripRenderer";
import { useParams } from "react-router-dom";
import { useProfileQuery } from "../queries/queries";
import { useEmojiFavicon } from "../utils/emojiToFavicon";
import { useUser } from "@clerk/clerk-react";
import { useUserContext } from "../contexts/UserContext";
import { NotFoundPage } from "./NotFoundPage";
import { WebsiteStrip } from "../components/WebsiteStrip";

export function Profile() {
  const [leaveModalOpened, setLeaveModalOpened] = useState(false);
  const { username } = useParams();

  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery(username);

  const { user } = useUser();
  const clerkUserId = user?.id;
  const isAdmin = clerkUserId === profileData?.clerkUserId;

  const { loggedInUsername, loggedInProfPic } = useUserContext();

  useEmojiFavicon(profileData?.emoji);

  function MyUserButton() {
    return (
      <Avatar
        component="a"
        href={`/${loggedInUsername}`}
        src={loggedInProfPic}
        size={64}
        radius={64}
        mx={20}
        style={{
          border: "3px solid white",
          boxShadow: "0 0 10px 3px rgba(58, 43, 226, 0.7)",
        }}
      />
    );
  }

  // function JoinTapistreeButton() {
  //   const navigate = useNavigate();
  //   const [isVisible, setIsVisible] = useState(true);

  //   if (isVisible) {
  //     return (
  //       <Affix position={{ bottom: "15px", right: "13px" }}>
  //         <Menu
  //           transitionProps={{ transition: "pop" }}
  //           withArrow
  //           position="bottom-end"
  //           withinPortal
  //         >
  //           <Menu.Target>
  //             <ActionIcon
  //               color="blue"
  //               variant="light"
  //               radius="xl"
  //               size="xl"
  //               shadow="lg"
  //               onClick={() => console.log}
  //               style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
  //             >
  //               <span style={{ fontSize: "32px" }}>🎨</span>
  //             </ActionIcon>
  //           </Menu.Target>
  //           <Menu.Dropdown>
  //             <Menu.Item
  //               icon={<IconX size="1rem" stroke={1.5} />}
  //               color="red"
  //               onClick={() => setIsVisible(false)}
  //             >
  //               Hide this thing
  //             </Menu.Item>
  //             <Menu.Item
  //               icon={<IconPlus size="1rem" stroke={1.5} />}
  //               color="green"
  //               onClick={() => navigate("/")}
  //             >
  //               Create a page?
  //             </Menu.Item>
  //           </Menu.Dropdown>
  //         </Menu>
  //       </Affix>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // }

  if (profileIsLoading) {
    return <LoadingOverlay visible={true} />;
  } else if (profileIsError) {
    return <NotFoundPage />;
  } else {
    return (
      <div className="body">
        <Affix position={{ bottom: "10px", right: "10px" }}>
          {isAdmin ? <></> : clerkUserId ? <MyUserButton /> : <></>}
        </Affix>
        <LeaveModal
          leaveModalOpened={leaveModalOpened}
          setLeaveModalOpened={setLeaveModalOpened}
        />
        <StripRenderer />
      </div>
    );
  }
}
