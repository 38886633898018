import "../App.css";
import { useLinksQuery } from "../queries/queries";
import { Image } from "@mantine/core";
import { Strip } from "./Strip";
import { ErrorBoundary } from "@sentry/react";
import { StravaFollowButton } from "./CTAButtons";
import { StravaCalendar } from "./StravaCalendar";
import { useParams } from "react-router-dom";

export function StravaStrip() {
  const STRIP = "Strava";

  const { data: linksData } = useLinksQuery();

  const { username } = useParams();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  const profileUrl =
    linksDataByStrip?.find((link) => link.isProfileUrl)?.url ||
    linksDataByStrip?.[0]?.url ||
    "";

  return (
    <Strip
      id="strava" //Lowercase
      nav="scroll"
      gradientValues={["#FFFFFF"]}
      title={
        <Image
          src="/api_logo_pwrdBy_strava_stack_light.svg"
          width={140}
          mt={-3}
          ml={-5}
        />
      }
      titleColor="#000000"
      followButton={<StravaFollowButton link={profileUrl} />}
      icon={{
        type: "image",
        icon: <Image src="/strava-icon.svg" height={32} width={32} mt={-2} />,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          color: "white",
        }}
      >
        <div style={{ marginLeft: "10px" }}>
          <ErrorBoundary>
            <StravaCalendar username={username} />
          </ErrorBoundary>
        </div>
      </div>
    </Strip>
  );
}
